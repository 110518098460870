<template>
    <div>
        <AdminTop
            :heading="lunchEventId ? 'Redigera lunchtid' : 'Skapa lunchtid'"
        />

        <BaseForm
            v-if="lunchEvent"
            :loading="loading"
            :submit-label="lunchEventId ? 'Uppdatera' : 'Lägg till'"
            @submit="handleSubmit"
        >
            <FormGroup
                label="Datum"
                name="date"
            >
                <DatePicker v-model="lunchEvent.date" />
            </FormGroup>

            <FormGroup
                label="Tid"
                name="time"
            >
                <div class="inline-flex items-center form-input">
                    <div>
                        <TimePicker
                            :initial-hour="lunchEvent.startHour"
                            :initial-minute="lunchEvent.startMinute"
                            @changed="(hour, minute) => { lunchEvent.startHour = hour; lunchEvent.startMinute = minute }"
                        />
                    </div>

                    <p class="mx-8 mb-0 text-3xl">
                        Till
                    </p>

                    <div>
                        <TimePicker
                            :initial-hour="lunchEvent.endHour"
                            :initial-minute="lunchEvent.endMinute"
                            @changed="(hour, minute) => { lunchEvent.endHour = hour; lunchEvent.endMinute = minute }"
                        />
                    </div>
                </div>
            </FormGroup>

            <FormGroup
                label="Repeterbar till och med"
                name="repeat_until"
            >
                <DatePicker v-model="lunchEvent.repeat_until" />
            </FormGroup>

            <FormGroup
                label="Elever"
                name="students"
            >
                <MultipleSelectField
                    v-if="users"
                    v-model="lunchEvent.users"
                    :items="users"
                />
            </FormGroup>
        </BaseForm>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseForm from '@/components/form/BaseForm';
import FormGroup from '@/components/form/FormGroup';
import TimePicker from '@/components/form/TimePicker';
import DatePicker from '@/components/form/DatePicker';
import MultipleSelectField from '@/components/form/MultipleSelectField';
import LunchEventService from '@/services/admin/LunchEventService';
import UserService from '@/services/admin/UserService';
import formatISO from 'date-fns/formatISO';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import formatISO9075 from 'date-fns/formatISO9075';
import parseISO from 'date-fns/parseISO';

export default {
    components: {
        AdminTop,
        BaseForm,
        FormGroup,
        TimePicker,
        DatePicker,
        MultipleSelectField
    },

    data: () => ({
        loading: false,
        lunchEvent: null,
        users: null
    }),

    computed: {
        lunchEventId () {
            return this.$route.params.id;
        }
    },

    async created () {
        const [lunchEvent, users] = await Promise.all([
            this.getLunchEvent(),
            UserService.students()
        ]);

        this.users = users.data;

        if (!this.lunchEventId) {
            lunchEvent.users = this.users.map(user => user.id);
        }

        this.lunchEvent = lunchEvent;
    },

    methods: {
        async getLunchEvent () {
            if (!this.lunchEventId) {
                return {
                    date: null,
                    startHour: 0,
                    startMinute: 0,
                    endHour: 0,
                    endMinute: 0,
                    repeat_until: null,
                    users: []
                };
            }

            const res = await LunchEventService.get(this.$route.params.id);

            const lunchEvent = res.data;

            lunchEvent.users = lunchEvent.users.map(user => user.id);

            const startDate = parseISO(lunchEvent.start);
            const endDate = parseISO(lunchEvent.end);

            lunchEvent.date = formatISO(startDate, { representation: 'date' });

            lunchEvent.startHour = getHours(startDate);
            lunchEvent.startMinute = getMinutes(startDate);

            lunchEvent.endHour = getHours(endDate);
            lunchEvent.endMinute = getMinutes(endDate);

            return lunchEvent;
        },

        setLunchEventDates () {
            if (!this.lunchEvent.date) {
                this.lunchEvent.start = null;
                this.lunchEvent.end = null;

                return;
            }

            const date = parseISO(this.lunchEvent.date);

            const startDate = setHours(date, this.lunchEvent.startHour);
            const endDate = setHours(date, this.lunchEvent.endHour);

            this.lunchEvent.start = formatISO9075(setMinutes(startDate, this.lunchEvent.startMinute));
            this.lunchEvent.end = formatISO9075(setMinutes(endDate, this.lunchEvent.endMinute));
        },

        async handleSubmit () {
            this.loading = true;

            this.setLunchEventDates();

            try {
                if (this.lunchEventId) {
                    await this.update();
                } else {
                    await this.create();
                }
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }

            this.loading = false;
        },

        async create () {
            const res = await LunchEventService.create(this.lunchEvent);

            if (res.status === 200 && res?.data?.lunchEvent?.id) {
                this.$router.push(`/admin/lunch/events/${res.data.lunchEvent.id}`);
            }
        },

        async update () {
            return await LunchEventService.update(this.lunchEventId, this.lunchEvent);
        }
    }
};
</script>
