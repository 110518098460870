import * as API from '../API.js';

export default {
    all () {
        return API.apiClient.get('/admin/lunch-events');
    },

    get (lunchEventId) {
        return API.apiClient.get(`/admin/lunch-events/${lunchEventId}`);
    },

    create (payload) {
        return API.apiClient.post('/admin/lunch-events', payload);
    },

    update (lunchEventId, payload) {
        return API.apiClient.put(
            `/admin/lunch-events/${lunchEventId}`,
            payload
        );
    },

    remove (lunchEventId) {
        return API.apiClient.delete(`/admin/lunch-events/${lunchEventId}`);
    }
};
